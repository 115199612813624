import {PageFlip} from 'page-flip';
import 'magnific-popup';

jQuery( document ).ready(function($) {
    
    var currentPage = 0;

    const pageFlip = new PageFlip(
    document.getElementById('book'), 
        {
            width: 688, // base page width
            height: 1004, // base page height
            minWidth: 300,

            size: "stretch",

            maxShadowOpacity: 0.5, // Half shadow intensity
            showCover: true,
            mobileScrollSupport: false // disable content scrolling on mobile devices
        }
    );

    pageFlip.loadFromHTML(document.querySelectorAll('.page'));

    pageFlip.on('flip', (e) => {
        currentPage = e.data;
        if (e.data == 0){
            $('.wrapper').addClass('closed');
        } else{
            $('.wrapper').removeClass('closed');
        }
    });


    $('#book').on('mousedown', (e) => {
        if (currentPage == 1){
            if (e.pageX < (window.innerWidth / 2)){
                $('.wrapper').addClass('closed');
            } else {
                $('.wrapper').removeClass('closed');
            }
        }
    });
    pageFlip.on('changeState', (e) => {
        
        if (currentPage == 1 && e.data != "read"){

            console.log(pageFlip.getCurrentPageIndex());
        }
    });


    $('.next-page').on('click', function(){
        pageFlip.flipNext();
    });
    $('.prev-page').on('click', function(){
        
        pageFlip.flipPrev();
        if (currentPage == 1){
            $('.wrapper').addClass('closed');
        }
    });

    $('.modal').magnificPopup({
        type:'inline',
        items: {
            src: '#wyng'
        },
    });

    $('#wyng').addClass('mfp-hide');
});